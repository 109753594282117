import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Container, Row, Col } from 'reactstrap';
import Slider from "react-slick";
import UniversalLink from "./UniversalLink"

export default class Worksmap extends React.Component {
  render() {
    return (
      <div className="portfolio-section">
        <Container>
          <Row>
          {this.props.works.map((work) => {
            return (
              <Col lg="4">
                <div className="works-box">
                  <div className="works-box-overlay"></div>
                  <img className="works-img" src={work.works.img.mediaItemUrl}></img>
                  <div className="works-box-content">
                    <work.works.titleTag className="works-title">{work.title}</work.works.titleTag>
                    <div className="works-text" dangerouslySetInnerHTML={{__html: work.content}}></div>
                    <UniversalLink to={"/works/" + work.slug} className="works-link">{work.works.link}</UniversalLink>
                  </div>
                </div>
              </Col>
            );
          })}
          </Row>
        </Container>
      </div>
    );
  }
}
