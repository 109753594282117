import React from "react"

export default class HeroPortfolio extends React.Component {
    render() {
  return (
    <div className="hero hero-portfolio" style={{ backgroundImage: `url(http://waterproof.lw-dev.com/wordpress/wp-content/uploads/2021/07/hero-background.jpg)` }}>
      <div className="hero-box">
        <h2 className="hero-title">Portfolio</h2>
      </div>
    </div>
           );
        }
    }
  
