import React from "react"
import Layout from "../components/Layout"
import Hero from "../components/HeroPortfolio"
import { useStaticQuery, graphql } from "gatsby"
import Question from "../components/Question"
import WorksPortfolio from "../components/WorksPortfolio"

const Portfolio = () => {
    const { allWpWork } = useStaticQuery(graphql`
    {
      allWpWork {
        nodes {
          content
          slug
          title
          works {
            img {
              altText
              mediaItemUrl
            }
            link
            titleTag
          }
        }
      }
    }
  `)
    return (
  <Layout>
    <div className="portfolio-page">
      <Hero></Hero>
      <WorksPortfolio works={allWpWork.nodes}></WorksPortfolio>
      <Question></Question>
    </div>
  </Layout>  
    )
    }

export default Portfolio;
 